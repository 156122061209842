import { component } from '../lib/components.js'

function Stylesheet (props) {
  if (globalThis.window) return ''

  return (
    link({ rel: 'stylesheet', ...props })
  )
}

export default component(Stylesheet)
