import { component } from '../lib/components.js'

function ConvertKitForm () {
  return form(
    {
      action: 'https://app.convertkit.com/forms/5865782/subscriptions',
      class: 'seva-form formkit-form',
      method: 'post',
      'data-sv-form': '5865782',
      'data-uid': 'a285c3fba1',
      'data-format': 'inline',
      'data-version': '5',
      'data-options': '{"settings":{"after_subscribe":{"action":"message","success_message":"Success! Now check your email to confirm your subscription.","redirect_url":""},"analytics":{"google":null,"fathom":null,"facebook":null,"segment":null,"pinterest":null,"sparkloop":null,"googletagmanager":null},"modal":{"trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"powered_by":{"show":false,"url":"https://convertkit.com/features/forms?utm_campaign=poweredby&utm_content=form&utm_medium=referral&utm_source=dynamic"},"recaptcha":{"enabled":false},"return_visitor":{"action":"show","custom_content":""},"slide_in":{"display_in":"bottom_right","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"sticky_bar":{"display_in":"top","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15}},"version":"5"}',
      'min-width': '400 500 600 700 800'
    },
    div({ class: 'formkit-background' }),
    div(
      { 'data-style': 'minimal' },
      ul({ class: 'formkit-alert formkit-alert-error', 'data-element': 'errors', 'data-group': 'alert' }),
      div(
        { 'data-element': 'fields', 'data-stacked': 'false', class: 'seva-fields formkit-fields' },
        span(
          { class: 'formkit-field' },
          input({
            class: 'formkit-input',
            name: 'email_address',
            'aria-label': 'Email Address',
            placeholder: 'Email Address',
            required: '',
            type: 'email'
          })
        ),
        button(
          { 'data-element': 'submit', class: 'formkit-submit button-link primary' },
          div({ class: 'formkit-spinner' }, div(), div(), div()),
          span('Get A Demo')
        )
      ),
      div({ class: 'formkit-guarantee', 'data-element': 'guarantee' })
    )
  );
}

export default component(ConvertKitForm)
