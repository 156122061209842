import { component } from '../lib/components.js'

function Left (props, children) {
  Object.assign(this.style, {
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    bottom: 0,
    left: 0,
    right: 'unset',
    'will-change': 'width',
    contain: 'layout paint size',
    width: props.width ?? '25%'
  })

  return children
}

function Right (props, children) {
  Object.assign(this.style, {
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    bottom: 0,
    left: 'unset',
    'will-change': 'width',
    contain: 'layout paint size',
    width: props.width ?? '75%',
    right: 0
  })

  return children
}

function Top (props, children) {
  Object.assign(this.style, {
    position: 'absolute',
    overflow: 'hidden',
    left: 0,
    height: props.height ?? '50%',
    right: 0,
    top: 0,
    'will-change': 'height',
    contain: 'layout paint size',
    bottom: 'unset',
    'z-index': 4
  })

  return children
}

function Bottom (props, children) {
  Object.assign(this.style, {
    position: 'absolute',
    overflow: 'hidden',
    left: 0,
    height: props.height ?? '50%',
    right: 0,
    'will-change': 'height',
    contain: 'layout paint size',
    bottom: 0,
    top: 'unset'
  })

  return children
}

Split.Left = component(Left)
Split.Right = component(Right)
Split.Bottom = component(Bottom)
Split.Top = component(Top)

function Split (props, ...children) {
  let left = children[0]
  let right = children[1]
  let top = children[0]
  let bottom = children[1]

  let dragging = false

  const panels = {}
  const meta = {}

  Object.assign(this.style, {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  })

  //
  // The split handle
  //
  let handle

  const onmouseover = () => {
    handle.style.backgroundColor = 'var(--x-accent)'
  }

  const onmouseout = () => {
    if (dragging) return
    handle.style.backgroundColor = 'var(--x-window)'
  }

  handle = div({ class: 'x--split-handle', onmouseover, onmouseout })

  const handleRules = props.type === 'vertical' ? {
    top: 0,
    width: '5px',
    bottom: 0,
    left: '25%',
    'border-left': '1px solid var(--x-border)',
    cursor: 'ew-resize'
  } : {
    top: '50%',
    height: '5px',
    left: 0,
    right: 0,
    'border-bottom': '1px solid var(--x-border)',
    cursor: 'ns-resize'
  }

  Object.assign(handle.style, {
    position: 'absolute',
    'z-index': 1,
    'user-select': 'none',
    '-webkit-user-select': 'none',
    'background-color': 'transparent',
    transition: 'background .1s ease',
    ...handleRules
  })

  //
  // Methods
  //
  const start = () => {
    dragging = true
    handle.style.backgroundColor = 'var(--x-accent)'
    this.setAttribute('dragging', true)
  }

  const cancel = () => {
    dragging = false
    handle.style.backgroundColor = 'var(--x-window)'
    this.removeAttribute('dragging')
  }

  const hide = (panel) => {
    if (panels[panel].hidden) return
    this.toggle(panel, false)
  }

  const show = (panel) => {
    if (!panels[panel].hidden) return
    this.toggle(panel, true)
  }

  const toggle = (panel, state) => {
    if (typeof state === 'boolean' && state === false) {
      delete meta[panel]
    }

    const previous = meta[panel]
    let opposite = ''
    let property = ''

    if (props.type === 'vertical') {
      opposite = panel === 'left' ? 'right' : 'left'
      property = 'width'
    } else {
      opposite = panel === 'top' ? 'bottom' : 'top'
      property = 'height'
    }

    if (!previous && !state) {
      //
      // First, save the state of the panel to hide and its opposite
      //
      meta[panel] = {
        [panel]: panels[panel].style[property],
        [panel + 'visibility']: panels[panel].style.visibility,
        [opposite]: panels[opposite].style[property],
        [opposite + 'visibility']: panels[opposite].style.visibility,
        handle: handle.style.display
      }

      //
      // Set the panel to hide as zero width, hide the handle
      // and set the opposite panel to fill the splitter
      //
      panels[panel].style[property] = 0
      panels[panel].style.visibility = 'hidden'
      panels[opposite].style[property] = '100%'
      panels[opposite].style.visibility = 'inherit'
      handle.style.display = 'none'
      return
    }

    //
    // If there is meta data, use it to restore the previous
    // property values. After restored, delete the meta data.
    //
    if (previous) {
      panels[panel].style[property] = previous[panel]
      panels[panel].style.visibility = previous[panel + 'visibility']
      panels[opposite].style[property] = previous[opposite]
      panels[opposite].style.visibility = previous[opposite + 'visibility']
      handle.style.display = previous.handle
      delete meta[panel]
    }
  }

  let timeout = null
  const afterResize = () => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      this.dispatchEvent(new window.CustomEvent('resize', { bubbles: true }))
    }, 64)
  }

  //
  // Events
  //
  const onmousemove = (e) => {
    if (!dragging) return

    const { x, y } = this.getBoundingClientRect()

    const w = this.offsetWidth
    const h = this.offsetHeight

    const max = parseInt(props.max, 10) || 25
    const min = parseInt(props.min, 10) || 25

    if (props.type === 'vertical') {
      let t = e.clientX - x

      if (t >= w - max) t = w - max
      if (t <= min) t = min

      const p = (t / w) * 100

      left.style.width = p + '%'
      handle.style.left = p + '%'
      right.style.width = (100 - p) + '%'
      afterResize()
      return
    }

    let t = e.clientY - y

    if (t >= h - max) t = h - max
    if (t <= min) t = min

    const p = (t / h) * 100

    if (p <= 100 - 5) {
      top.style.height = p + '%'
      handle.style.top = p + '%'
      bottom.style.height = (100 - p) + '%'
      afterResize()
    }
  }

  const onmousedown = (e, match) => {
    const handle = match('.x--split-handle')

    if (handle && handle.parentElement === this) {
      e.preventDefault() // prevent selection chaos
      start()
    }
  }

  const onmouseenter = e => {
    if (e.buttons === 0) cancel()
  }

  const onmouseleave = e => {
    if (e.buttons === 0) cancel()
  }

  const onmouseup = () => {
    cancel()
  }

  const onready = () => {
    cancel()
  }

  return [
    hide,
    show,
    toggle,

    onready,
    onmouseup,
    onmousedown,
    onmouseleave,
    onmouseenter,
    onmousemove,

    left,
    handle,
    right
  ]
}

export default component(Split)
