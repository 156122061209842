import { component } from '../lib/components.js'

function Head (props, ...children) {
  return [
    title({ 'data-key': 'title' }, props?.title || 'Untitled'),

    link({ rel: 'icon', type: 'image/png', sizes: '16x16', href: '/images/favicon-16.png' }),
    link({ rel: 'icon', type: 'image/png', sizes: '32x32', href: '/images/favicon-32.png' }),
    link({ rel: 'icon', type: 'image/svg+xml', sizes: 'any', href: '/images/favicon.svg' }),
    link({ rel: 'mask-icon', href: '/images/mask-icon.svg', color: '#36393d' }),
    link({ rel: 'apple-touch-icon', sizes: '180x180', href: '/images/apple-touch-icon.png' }),

    meta({ 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' }),
    meta({ name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1 user-scalable=no' }),

    meta({ name: 'description', content: '', 'data-key': 'description' }),

    meta({ property: 'og:type', content: 'website' }),
    meta({ property: 'og:url', content: 'https://socketsaupply.co', 'data-key': 'url' }),
    meta({ property: 'og:site_name', content: 'Socket Supply Co.' }),
    meta({ property: 'og:title', content: 'Socket Supply Co.', 'data-key': 'title' }),
    meta({ property: 'og:description', content: 'Build native apps for desktop and mobile using any frontend library.', 'data-key': 'description' }),
    meta({ property: 'og:image', content: 'https://socketsupply.co/images/social.png' }),

    meta({ name: 'twitter:card', content: 'summary_large_image' }),
    meta({ name: 'twitter:site', content: '@socketsupply' }),
    meta({ name: 'twitter:title', content: 'Socket Supply Co.', 'data-key': 'title' }),
    meta({ name: 'twitter:image', content: 'https://socketsupply.co/images/social.png' }),
    meta({ name: 'twitter:description', content: 'Build native apps for desktop and mobile using any frontend library.', 'data-key': 'description' }),

    script({}, "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-NVHXH6K');"),

    noscript({}, iframe({ src: 'https://www.googletagmanager.com/ns.html?id=GTM-NVHXH6K', height: '0', width: '0', style: 'display:none;visibility:hidden' })),

    script({}, "_linkedin_partner_id = '5517026'; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id); (function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName('script')[0]; var b = document.createElement('script'); b.type = 'text/javascript';b.async = true; b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'; s.parentNode.insertBefore(b, s);})(window.lintrk);"),
    
    noscript({}, img({ height: '1', width: '1', style: 'display:none;', alt: '', src: 'https://px.ads.linkedin.com/collect/?pid=5517026&fmt=gif' })),

    link({ rel: 'stylesheet', href: '/css/theme.css' }),
    link({ rel: 'stylesheet', href: '/css/globals.css' }),
    link({ rel: 'stylesheet', href: '/css/nav.css' }),
    link({ rel: 'stylesheet', href: '/css/tree.css' }),
    link({ rel: 'stylesheet', href: '/css/slider.css' }),
    link({ rel: 'stylesheet', href: '/css/footer.css' }),
    link({ rel: 'stylesheet', href: '/css/code.css' }),
    link({ rel: 'stylesheet', href: '/css/tree.css' }),
    ...children
  ]
}

export default component(Head)
