import { component } from '../lib/components.js'

async function Navigation () {
  const onscroll = () => {
    const nav = this.querySelector('nav')
    const background = nav.querySelector('.background')
    const pos = document.documentElement.scrollTop || document.body.scrollTop
    const alpha = Math.min(pos / 100, 1)
    background.style.opacity = alpha

    const delta = Math.min(alpha, 0.35)

    if (alpha > 0) {
      nav.classList.add('scrolling')
    } else {
      nav.classList.remove('scrolling')
    }

    nav.style.boxShadow = `0px -4px 18px 0px rgba(0,0,0,${delta})`
  }

  this.addEventListener('ready', () => {
    onscroll()
    document.addEventListener('scroll', () => onscroll())
  })

  return nav({ class: 'nav-wrapper' },
    div({ class: 'background' }),
    div({ class: 'links-left' },
      a({ href: 'https://github.com/socketsupply/socket', title: 'Github' },
        svg({ 'data': { id: 'github-logo' } },
          use({ href: '/images/sprite.svg#github-logo' })
        )
      ),
      a({ href: '/apis', class: 'with-hover', title: 'APIs' }, 'APIs')
    ),
    a({ href: '/', class: 'logo tc-logo', alt: 'Socket Supply Co.' },
      svg({ style: { height: '40' } },
        use({ href: '/images/sprite.svg#socket-logo', fill: 'currentColor' })
      ),
      div({ class: 'hover' },
        span(null, 'Live Fast. Die Last.')
      )
    ),
    div({ class: 'links-right' },
      a({ href: '/guides', class: 'with-hover', title: 'Guides' }, 'Guides'),
      // a({ href: '/blog/', class: 'with-hover', title: 'Blog' }, 'Blog'),
      a({ href: 'https://github.com/socketsupply/socket-examples', class: 'with-hover', title: 'Examples' }, 'Examples')
    ),
    div({ class: 'menu' })
  )
}

export default component(Navigation)
