import { component } from '../lib/components.js'

async function Command () {
  const onready = () => {
    const commandLines = this.querySelectorAll('.command-line-text')

    commandLines.forEach(el => handleCommandLines(el))

    function handleCommandLines (elem) {
      const copyIcon = elem.querySelector('.copy-icon')

      copyIcon.addEventListener('click', () => {
        navigator.clipboard.writeText(elem.innerText)

        elem.classList.add('copied')
        elem.classList.add('clicked')
        elem.querySelector('.not-copied-icon').addEventListener('animationend', () => elem.classList.remove('clicked'), true)
        setTimeout(() => elem.classList.remove('copied'), 4000)
      })
    }
  }

  return div(
    {
      class: 'command-wrapper',
      onready
    },
    div({ class: 'code-snippet-header' },
      p({ class: 'code-snippet-title' }, this.props.path)
    ),
    div({ class: 'code-snippet' },
      pre(
        code(null, this.elements)
      )
    )
  )
}

export default component(Command)
