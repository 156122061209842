import { component } from '../lib/components.js'

function Footer() {
  return div({ id: 'primary', class: 'tc-footer' },
    div({ class: 'content' },
      div({ class: 'flex' },
        div(
          h4('Company'),
          ul(
            li(a({ href: '/blog', alt: 'Blog' }, 'Blog')),
            li(a({ href: '/jobs', alt: 'Jobs' }, 'Jobs')),
            li(a({ href: 'https://github.com/socketsupply', rel: 'noopener', class: 'underline', alt: 'Github' }, 'Github')),
            li(a({ href: 'https://youtube.com/@socketsupply', rel: 'noopener', class: 'underline', alt: 'Youtube' }, 'Youtube'))
          )
        ),
        div(
          h4('Developer'),
          ul(
            li(a({ href: '/guides', alt: 'Socket Runtime Guides' }, 'Socket Runtime Guides')),
            li(a({ href: '/apis', alt: 'Socket Runtime APIs' }, 'Socket Runtime APIs')),
            li(a({ href: 'https://github.com/socketsupply/socket-examples', alt: 'Socket Runtime Examples' }, 'Socket Runtime Examples')),
            li(a({ href: 'https://github.com/socketsupply/socket/blob/master/CONTRIBUTING.md', alt: 'Socket Runtime contributing guide' }, 'Contribute to Socket Runtime')),
            li(a({ href: 'mailto:sales@socketsupply.co?subject=Demo Booking&body=Hi, I\'m interested in a demo of Socket Operator!', alt: 'Socket Operator' }, 'Socket Operator'))
          )
        ),
        div(
          h4('Contact'),
          ul(
            li(a({ href: 'mailto:sales@socketsupply.co', alt: 'Email' }, 'Email')),
            li(a({ href: 'https://twitter.com/socketsupply', rel: 'noopener', class: 'underline', alt: 'Twitter' }, 'Twitter')),
            li(a({ href: 'https://discord.gg/YPV32gKCsH', rel: 'noopener', class: 'underline', alt: 'Discord' }, 'Discord')),
            li(a({ href: 'https://www.linkedin.com/company/socketsupply', rel: 'noopener', class: 'underline', alt: 'LinkedIn' }, 'LinkedIn'))
          )
        )
      ),
      p({ class: 'copy' },
        span({ class: 'copyright' }, ' Socket Supply Co. 2024')
      )
    )
  )
}

export default component(Footer)
