import { component } from '../lib/components.js'

function CopyIcon (props) {
  Object.assign(this.style, {
    background: 'transparent',
    height: '24px',
    width: '24px',
  })

  const onclick = async () => {
    const target = document.getElementById(props.for)
    await navigator.clipboard.writeText(target.value)

    const elUse = this.querySelector('use')
    elUse.setAttribute('href', '/images/sprite.svg#check-icon')
    this.classList.add('confirm')

    setTimeout(() => {
      this.classList.remove('confirm')
      elUse.setAttribute('href', '/images/sprite.svg#copy-icon')
    }, 512)
  }

  return button({ type: 'icon', onclick },
    svg(use({ href: '/images/sprite.svg#copy-icon' }))
  )
}

export default component(CopyIcon)
