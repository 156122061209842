import { component } from '../lib/components.js'
import CopyIcon from '../components/copy-icon.js'

const singleQuoteString = /'[^']*'/g
const btQuoteString = /`[^`]*`/g
const angleBracketString = /<[^>]*>/g
const jsModule = /\b(import|export)\b/g
const jsKeywords = /\b(abstract|await|boolean|break|byte|case|catch|char|class|const|continue|debugger|default|delete|do|double|else|enum|extends|false|final|finally|float|for|function|goto|if|implements|in|instanceof|int|interface|long|native|new|null|private|protected|public|return|short|static|super|switch|synchronized|this|throw|throws|transient|true|try|typeof|var|void|volatile|while|with|yield)\b/g
const jsSymbols = /[{}[\]().,:;<>+=\-*/%&|^!~?]/g
const inlineComment = /(?:^|[^:\w])(\s*(\/\/|#).*$)/g
const upperCase = /\b[A-Z]+\b/g
const blockComment = /\/\*[\s\S]*?\*\//g

const languages = {
  default: [
    { regex: inlineComment, type: 'comment' },
    { regex: blockComment, type: 'comment' },
    { regex: singleQuoteString, type: 'string' },
    { regex: btQuoteString, type: 'string' },
    { regex: angleBracketString, type: 'bracket' },
    { regex: jsKeywords, type: 'keyword' },
    { regex: jsModule, type: 'module' },
    { regex: jsSymbols, type: 'symbol' }
  ],
  md: [
    { regex: inlineComment, type: 'comment' },
    { regex: btQuoteString, type: 'string' },
    { regex: jsSymbols, type: 'symbol' },
    { regex: upperCase, type: 'uppercase' }
  ],
  css: [
    { regex: /[a-zA-Z][a-zA-Z0-9-_]*(?=\s*{)/g, type: 'keyword' },
    { regex: /[a-zA-Z-]+(?=\s*:)/g, type: 'comment' },
    { regex: blockComment, type: 'comment' },
    { regex: jsSymbols, type: 'symbol' }
  ],
  ini: [
    { regex: /^\s*\[([^\]]+)\]\s*$/g, type: 'keyword' },
    { regex: /^\s*([^=\s]+)\s*(=|:)\s*(.*)\s*$/g, type: 'string' },
    { regex: jsSymbols, type: 'symbol' }
  ],
  ascii: [
  ]
}

function parse (code, lang) {
  const patterns = languages[lang] || languages.default

  const matches = []
  let lastIndex = 0

  while (lastIndex < code.length) {
    let closestMatch = null
    let closestIndex = code.length

    patterns.forEach(pattern => {
      pattern.regex.lastIndex = lastIndex
      const match = pattern.regex.exec(code)
      if (match && match.index < closestIndex) {
        closestMatch = { value: match[0], type: pattern.type, index: match.index }
        closestIndex = match.index
      }
    })

    if (closestMatch) {
      if (closestMatch.index > lastIndex) {
        matches.push({ value: code.slice(lastIndex, closestMatch.index), type: 'text' })
      }
      matches.push({ value: closestMatch.value, type: closestMatch.type })
      lastIndex = closestMatch.index + closestMatch.value.length
    } else {
      matches.push({ value: code.slice(lastIndex), type: 'text' })
      break
    }
  }

  return matches.map(match => {
    if (match.type !== 'text') return span({ class: `hl-${match.type}` }, match.value)
    return match.value
  })
}

async function CodeExample (props) {
  const value = props.value.split('\n')
  const leadingSpaces = 0

  this.id = Math.random().toString(16).slice(2)

  Object.defineProperty(this, 'value', {
    get: function () {
      return [...this.querySelectorAll('.code-line')].map(el => {
        return el.querySelector('.line-content').textContent
      }).join('\n')
    }
  })

  let svgElement
  if (props.lang === 'mermaid') {
    svgElement = parseMermaid(props.value)
  }

  return [
    header(
      { title: props.title },
      props.title,
      CopyIcon({ for: this.id })
    ),
    svgElement || div({ class: 'code-block' },
      value
        .map((line, index) => {
          const isEmpty = line.trim() === ''
          if (((index === 0) && isEmpty) || ((index === value.length - 1) && isEmpty)) {
            return undefined // filter this out.
          }

          const lineNo = span({ class: 'line-number' }, String(index))
          const content = span({ class: 'line-content' }, ...parse(line, props.lang))
          return div({ class: 'code-line' }, lineNo, content)
        })
        .filter(Boolean)
    )
  ]
}

function parseMermaid (mermaidCode) {
  const diagram = parseSequenceDiagram(mermaidCode)
  return generateSVG(diagram)
}

function parseSequenceDiagram (code) {
  const lines = code.split('\n').map(line => line.trim())
  const participants = new Set()
  const messages = []

  lines.forEach(line => {
    const messageMatch = line.match(/^(\w+)->(\w+):(.*)$/)
    if (messageMatch) {
      const [, from, to, message] = messageMatch
      participants.add(from)
      participants.add(to)
      messages.push({ from, to, message })
    }
  })

  return { participants: Array.from(participants), messages }
}

function generateSVG (diagram) {
  const { participants, messages } = diagram
  const width = 200 * participants.length
  const height = 50 * (messages.length + 2)
  const participantY = 20
  const messageYStart = 50
  const participantGap = 200

  const svgNamespace = 'http://www.w3.org/2000/svg'
  const svg = document.createElementNS(svgNamespace, 'svg')
  svg.setAttribute('width', width)
  svg.setAttribute('height', height)

  // Define arrowhead marker
  const defs = document.createElementNS(svgNamespace, 'defs')
  const marker = document.createElementNS(svgNamespace, 'marker')
  marker.setAttribute('id', 'arrow')
  marker.setAttribute('markerWidth', '10')
  marker.setAttribute('markerHeight', '10')
  marker.setAttribute('refX', '10')
  marker.setAttribute('refY', '3')
  marker.setAttribute('orient', 'auto')
  marker.setAttribute('markerUnits', 'strokeWidth')

  const path = document.createElementNS(svgNamespace, 'path')
  path.setAttribute('d', 'M0,0 L0,6 L9,3 z') // Arrowhead shape
  path.setAttribute('fill', 'var(--x-primary)')
  marker.appendChild(path)
  defs.appendChild(marker)
  svg.appendChild(defs)

  participants.forEach((participant, index) => {
    const x = index * participantGap + 100
    const text = document.createElementNS(svgNamespace, 'text')
    text.setAttribute('x', x)
    text.setAttribute('y', participantY)
    text.setAttribute('fill', 'var(--x-primary)')
    text.setAttribute('text-anchor', 'middle')
    text.textContent = participant
    svg.appendChild(text)

    const line = document.createElementNS(svgNamespace, 'line')
    line.setAttribute('x1', x)
    line.setAttribute('y1', participantY + 10)
    line.setAttribute('x2', x)
    line.setAttribute('y2', height)
    line.setAttribute('stroke', 'var(--x-primary)')
    svg.appendChild(line)
  })

  messages.forEach((message, index) => {
    const fromIndex = participants.indexOf(message.from)
    const toIndex = participants.indexOf(message.to)
    const y = messageYStart + index * 50
    const fromX = fromIndex * participantGap + 100
    const toX = toIndex * participantGap + 100

    const line = document.createElementNS(svgNamespace, 'line')
    line.setAttribute('x1', fromX)
    line.setAttribute('y1', y)
    line.setAttribute('x2', toX)
    line.setAttribute('y2', y)
    line.setAttribute('stroke', 'var(--x-primary)')
    line.setAttribute('marker-end', 'url(#arrow)')
    svg.appendChild(line)

    const text = document.createElementNS(svgNamespace, 'text')
    text.setAttribute('x', (fromX + toX) / 2)
    text.setAttribute('y', y - 5)
    text.setAttribute('fill', 'var(--x-primary)')
    text.setAttribute('text-anchor', 'middle')
    text.textContent = message.message
    svg.appendChild(text)
  })

  return svg
}

export default component(CodeExample)
