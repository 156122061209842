import { component } from '../lib/components.js'

function Slider () {
  this.state.step ??= 0
  this.state.counter ??= 0

  const onclick = (e) => {
    const el = e.target.closest('[data-event]');
    if (!el) return;

    const { event } = el.dataset;

    const arrowLeft = document.querySelector('.arrow-left');
    const arrowRight = document.querySelector('.arrow-right');
    const slider = document.querySelector('.slide-list');
    const slideNum = slider.children.length;

    arrowLeft.classList.add('disabled');

    const setArrowStyles = () => {
      if (this.counter === slideNum - 1) {
        arrowRight.classList.add('disabled');
      } else if (this.counter === 0) {
        arrowLeft.classList.add('disabled');
      } else {
        arrowRight.classList.remove('disabled');
        arrowLeft.classList.remove('disabled');
      }
    };

    setArrowStyles();

    if (event === 'slide-prev') {
      if (this.counter === 0) return;

      this.step += 100;
      slider.style.transform = 'translateX(' + this.step + '%)';
      this.counter--;
      setArrowStyles();
    }

    if (event === 'slide-next') {
      if (this.counter === slideNum - 1) return;

      this.step -= 100;
      slider.style.transform = 'translateX(' + this.step + '%)';
      this.counter++;
      setArrowStyles();
    }
  }

  return div({ class: 'review-slider', onclick },
    div({ class: 'arrow-right', 'data-event': 'slide-next' },
      svg({ width: '14', height: '29', viewBox: '0 0 14 29', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
        path({ d: 'M2 2L12 14.5L2 27', stroke: 'currentColor', 'stroke-width': '4', 'stroke-linecap': 'round', 'stroke-linejoin': 'round' })
      )
    ),
    div({ class: 'arrow-left', 'data-event': 'slide-prev' },
      svg({ width: '14', height: '29', viewBox: '0 0 14 29', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
        path({ d: 'M12 2L2 14.5L12 27', stroke: 'currentColor', 'stroke-width': '4', 'stroke-linecap': 'round', 'stroke-linejoin': 'round' })
      )
    ),
    ul({ class: 'slide-list' },
      li({ class: 'slide' },
        img({ src: '../images/slider/slide-1.jpg', width: '130', height: '130' }),
        p(
          `"I've never heard anybody prefer React Native to just regular old React, React Native seems kinda pointless now."`,
          span('— Mikeal Rogers, Founder NodeConf')
        )
      ),
      li({ class: 'slide' },
        img({ src: '../images/slider/slide-2.jpg', width: '130', height: '130' }),
        p(
          `"P2P in the runtime will make a huge difference for apps - outside pressure like this (and React Native) is what ultimately drives the web platform forward."`,
          span('— Chris Anderson, Co-Creator of CouchDB')
        )
      ),
      li({ class: 'slide' },
        img({ src: '../images/slider/slide-3.jpg', width: '130', height: '130' }),
        p(
          `"Great to see continued innovation in this area. especially great to see based around the web. something i have been pushing and trying to build since 2007"`,
          span('— Jeff Haynie, CEO & Founding Engineer at Appcelerator')
        )
      ),
      li({ class: 'slide' },
        img({ src: '../images/slider/slide-4.jpg', width: '130', height: '130' }),
        p(
          `"Socket Runtime is exciting [...it] addresses users' biggest complaints with Electron, [...] it also works on mobile."`,
          span('— Feross Aboukhadijeh, CEO & Founder at Socket Security')
        )
      )
    )
  )
}

export default component(Slider)
