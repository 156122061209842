import { root } from '../lib/components.js'

import CodeExample from '../components/code.js'
import ConvertKitForm from '../components/convertkit-form.js'
import Stylesheet from '../components/stylesheet.js'

import '../components/index.js'

function Install() {
  return div({ class: 'install' },
    input({ type: 'radio', id: 'option-npm', name: 'installer' }),
    label({ for: 'option-npm' }, 'npm'),
    code('npm i @socketsupply/socket -g'),

    input({ type: 'radio', checked: true, id: 'option-curl', name: 'installer' }),
    label({ for: 'option-curl' }, 'curl'),
    code('. <(curl -s -o- https://socketsupply.co/sh)'),

    input({ type: 'radio', id: 'option-iwr', name: 'installer' }),
    label({ for: 'option-iwr' }, 'iwr'),
    code('iwr -useb https://socketsupply.co/ps | iex'),

    div({ class: 'copy-icon' })
  )
}

function Intro() {
  return section({ class: 'intro full-height' },
    div({ class: 'intro-wrapper' },
      h1('Socket Runtime'),
      h2('Build Native Desktop & Mobile Apps', br(), 'using 100% Web Technology'),
      div({ class: 'illustration' },
        object({ type: 'image/svg+xml', data: '/images/hero-package.svg' }),
        object({ type: 'image/svg+xml', data: '/images/hero-arrow.svg', id: 'illustration-arrow' }),
        object({ type: 'image/svg+xml', data: '/images/hero-devices.svg' }),
        p({ class: 'centered' }, 'Use the Web technology that everyone already knows.'),
        span(),
        p({ class: 'centered' }, 'To build native apps that run on any Operating System.')
      ),
      Install(),
      a({ class: 'button-link primary', href: '/guides#get-started' }, 'Get Started')
    )
  )
}

function Feature({ title, description }) {
  return div({ class: 'feature' },
    h4(title),
    p(description)
  )
}

function FeatureGrid() {
  return section({ class: 'illustrated feature-grid' },
    h3({ id: 'feature-header' }, 'Feature Highlights'),

    Feature({
      title: 'Browser Compatible',
      description: 'It\'s compatible with the Web. All the code you\'ve already written for the browser will \'just work\' in Socket.'
    }),
    Feature({
      title: 'Server Compatible',
      description: 'It implements most Node.js APIs so it can run local servers anywhere. Providing an instantly familiar development model.'
    }),
    Feature({
      title: '100% JavaScript / TypeScript',
      description: 'Other solutions get you started with JS, but then require you to learn a new language when it comes to non-trivial work.'
    }),
    Feature({
      title: 'Faster',
      description: 'It used to be true that native apps were faster. But Socket apps are GPU accelerated so they can run just as fast and smooth as native apps.'
    }),
    Feature({
      title: 'Smaller Bundles',
      description: 'Uses the Operating System\'s WebView component, so it doesn\'t need to ship an entire copy of a browser with each app.'
    }),
    Feature({
      title: 'Batteries Included',
      description: 'Native APIs like Camera, Filesystem, etc work out of the box. We also provide an API for creating native extensions in any language.'
    }),
    Feature({
      title: 'New Capabilities',
      description: 'Real-time & async P2P networking connect unlimited users anywhere — no servers or cloud services required.'
    }),
    Feature({
      title: 'Tray & Headless Apps',
      description: 'Socket makes it trivial to create tray and headless apps with a single configuration option.'
    }),
    Feature({
      title: 'Consistent',
      description: 'We normalize the webview to work the same on every OS, desktop and mobile. Service Workers, Web Workers, WHATWG Fetch, etc.'
    })
  )
}

const codeExample1 = {
  title: 'src/index.js',
  value: `
    import { useState, useEffect } from 'react';

    export default function App () {
      const [data, setData] = useState(null);

      useEffect(() => fetch('/greeting')
        .then(res => res.json())
        .then(setData)
      , []);

      return <h1>{data}</h1>;
    }
  `
}

const codeExample2 = {
  title: 'src/workers/greeting.js',
  value: `
    import fs from 'socket:fs/promises';

    export default async (req, ctx) => {
      const data = await fs.readFile('/greeting.txt');

      const headers = {
        'Content-Type': 'text/html'
      };

      return new Response(data, { code: 200 });
    }
  `
}

function HowItWorks () {
  return section({ class: 'demo' },
    div({ class: 'value-prop' },
      h3('Use Any Frontend Library'),
      p('No need to learn all the caveats of React Native when you can get the same perforamce with regular React or any other library.')
    ),
    div({ class: 'value-prop' },
      h3('No IPC Required'),
      p('Keep your code compatible with the browser by using Node.js-compatible service workers to handle your business logic.')
    ),
    CodeExample(codeExample1),
    CodeExample(codeExample2)
  )
}

function MoreSecure () {
  return section({ class: 'more-secure' },
    object({ type: 'image/svg+xml', data: '/images/more-secure.svg' }),
    p(
      h3('More Secure'),
      'Socket is not a framework or library. It\'s a runtime, it\'s all JavaScript and it\'s fully sandboxed — ',
      'creating a true barrier between a developer\'s code and the user\'s OS. That reduces your liabilities.',
      br(), br(),
      'Access to native APIs are hardened by build-time entitlements, user consent, ',
      'and a fine-grained CSP that can apply scope to ANY native API call. Socket ',
      'apps significantly more secure and memory safe than similar cross platform',
      'apps that run native code.'
    )
  )
}

function FarEdge () {
  return section({ class: 'far-edge' },
    object({ type: 'image/svg+xml', data: '/images/far-edge.svg' }),
    p(
      h3('Advanced Network Capabilities'),
      'The Socket Runtime was purpose-built for rapidly deploying applications to environments ',
      ' that have denied, degraded, intermittent, or limited connectivity.',
      br(), br(),
      'We introduce networking capabilities that can guarantee continuity in the worst conditions. ',
      'Distributing work to the far-edge can can de-risk mission-critical operations, reduce points of failure, and result in significant cost savings.',
      br(), br(),
      'Contact us for pricing and a tailored technology demo.',
      br(), br(),
      ConvertKitForm()
    )
  )
}

async function App () {
  return [
    Stylesheet({ href: '/css/pages/index.css' }),
    Navigation(),
    main(
      Intro(),
      HowItWorks(),
      MoreSecure(),
      FeatureGrid(),
      FarEdge()
    ),
    Footer()
  ]
}

export default root(App)
